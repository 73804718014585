import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { TabNavLink } from '../../common/components/TabNavLink/TabNavLink';
import { notForP0 } from '../../common/config/flags';
import { getStrings } from '../../common/utils';
import { isSweeps } from '@/configs/brands.config';

export default function SettingsWrapper() {
  const [
    {
      SettingsPage: { RouteName },
    },
  ] = getStrings();

  return (
    <>
      <Flex
        direction="row"
        flexWrap="wrap"
        justify="start"
        sx={{
          borderBottom: '1px solid var(--bc-colors-gray-200)',
        }}
      >
        <TabNavLink to="/settings/limits">{RouteName.Limits}</TabNavLink>
        <TabNavLink to="/settings/offerings">{RouteName.Offerings}</TabNavLink>
        {notForP0 && (
          <TabNavLink to="/settings/betfair-integration">
            {RouteName.BetfairIntegration}
          </TabNavLink>
        )}
        <TabNavLink to="/settings/system-setup">
          {RouteName.SystemSetup}
        </TabNavLink>
        {notForP0 && (
          <TabNavLink to="/settings/admin-accounts">
            {RouteName.AdminAccounts}
          </TabNavLink>
        )}
        <TabNavLink to="/settings/activity-log">
          {RouteName.ActivityLog}
        </TabNavLink>
        <TabNavLink to="/settings/your-profile">
          {RouteName.YourProfile}
        </TabNavLink>
        {!isSweeps && (
          <TabNavLink to="/settings/cms?type=quick-links">CMS</TabNavLink>
        )}
      </Flex>

      <Outlet />
    </>
  );
}
