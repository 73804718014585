import React from 'react';
import { Flex, Text, Image, Badge } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { EGeneralStatus } from '../../../../lib/DBModels';
import {
  centsToDollars,
  getIconAssetPath,
  getPlaceFromNumber,
  getPrettyPriceType,
  getStrings,
} from '../../../utils';
import { TEventMarketInfo } from '../EventMarketInfo';
import {
  BoldText,
  MBLFlag,
  MultiHeading,
  FlexWrapper,
} from '../EventMarketInfo.styles';
import StatusBadge from '../../StatusBadge';
import IconSvg from '@/components/IconSvg/IconSvg';
import SGMLogo from '@/assets/icons/sgm-icon.svg';
import SRMLogo from '@/assets/icons/srm-icon.svg';
import { ERequestBetType } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';

export const Header = ({ bet }: TEventMarketInfo) => {
  const [
    {
      BetApprovalsPage: { Table: Strings },
    },
  ] = getStrings();

  const location = useLocation();
  const isExoticBet = bet?.type?.toLowerCase() === 'exotics';
  const isTote =
    (bet?.bet_legs &&
      (bet?.bet_legs[0]?.price_type === 'tote_single_mid' ||
        bet?.bet_legs[0]?.price_type === 'tote_single_best')) ||
    bet?.price_type === 'tote_single_mid' ||
    bet?.price_type === 'tote_single_best';
  const isMulti = !!bet?.bet_legs && !isTote;

  const isRace = bet?.event_type?.toLowerCase() !== 'match';
  const eventLink = {
    pathname: `/trade-manager/${
      isExoticBet ? 'race' : bet?.event_type?.toLowerCase()
    }-details`,
    search: `?id=${
      isExoticBet ? bet?.bet_legs && bet?.bet_legs[0]?.event_id : bet?.event_id
    }`,
    state: { from: location },
  };

  const leg = bet.bet_legs && bet?.bet_legs[0];

  const raceDetails =
    isTote && bet?.bet_legs
      ? `${leg?.event_data?.market_name} (${isTote && 'TOTE'}) - ${
          leg?.event_data?.race_number
        }. ${leg?.event_data?.runner_name}`
      : `${bet?.proposition_type} ${isTote ? 'TOTE' : ''} - ${
          bet?.runner_number
        }. ${bet?.runner_name?.toLowerCase()}`;

  const sportDetails = `${bet?.event_subtitle?.toLocaleLowerCase() ?? ''} - ${
    bet?.market_name
  }`;

  const isSGMBet = bet?.type === ERequestBetType.SGMulti;
  const isSRMBet = bet?.type === ERequestBetType.SRMulti;
  const isBlended = bet.type === ERequestBetType.Blended;
  const isMysteryBet = bet?.price_type === 'mystery_bet';
  const hasRollover = isMysteryBet && (bet?.bet_legs?.length ?? 0) > 1;

  const sgmEventLink = bet.bet_legs &&
    bet.bet_legs[0] && {
      pathname: `/trade-manager/${bet.bet_legs[0]?.event_type?.toLowerCase()}-details`,
      search: `?id=${bet.bet_legs[0]?.event_id}&market=SGM`,
      state: { from: location },
    };

  const evenInfoFromLegs =
    bet.bet_legs &&
    `${bet.bet_legs[0]?.event_data?.venue_name} - R${bet.bet_legs[0]?.event_data?.race_number}`;

  if (isMysteryBet && (bet.bet_legs?.length ?? 0) > 1)
    return (
      <MultiHeading>
        <IconSvg
          name={getIconAssetPath('sports', 'square-question')}
          sx={{ boxSize: '4', mr: '2', cursor: 'pointer' }}
        />
        {`Mystery Bet ${hasRollover ? 'with Rollover' : ''}`}
      </MultiHeading>
    );

  const isMoM = bet?.bet_legs?.some((bLeg) => !!bLeg?.legs);
  const totalMoMLegs = bet?.bet_legs?.reduce(
    (count, bet_leg) =>
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      count +
      (bet_leg?.bet_description === 'Blended' || !bet_leg?.legs
        ? 1
        : bet_leg?.legs.length ?? 0),
    0
  );

  const legCount = isMoM ? totalMoMLegs : bet?.bet_legs?.length;

  return (
    <FlexWrapper>
      {isMulti && !isExoticBet ? (
        <>
          <MultiHeading>
            {legCount} Leg{' '}
            {isMysteryBet && (
              <>
                <IconSvg
                  name={getIconAssetPath('sports', 'square-question')}
                  sx={{ boxSize: '4', mr: '2', cursor: 'pointer' }}
                />
                {`Mystery Bet ${hasRollover ? 'with Rollover' : ''}`}
              </>
            )}
            {isBlended ? 'Blended' : isSRMBet ? '' : 'Multi'}
            {isSRMBet && (
              <Image src={SRMLogo} ml="1" alt="Same Race Multi Header Icon" />
            )}
            {isSGMBet && (
              <Image src={SGMLogo} ml="1" alt="Same Game Multi Icon" />
            )}
          </MultiHeading>
          {isSGMBet && bet.bet_legs && bet.bet_legs[0] && (
            <Link to={sgmEventLink ?? ''}>
              {bet?.bet_legs[0]?.event_data?.match_name}
            </Link>
          )}
        </>
      ) : (
        <Link to={eventLink}>
          <Flex
            alignItems="start"
            justifyItems="center"
            mb="1"
            flexDir="column"
          >
            {!isExoticBet && (
              <StatusBadge
                size="sm"
                status={bet.event_status as EGeneralStatus}
                sx={{ mr: 1 }}
              />
            )}

            <Flex flexDir="row">
              <BoldText>
                {isExoticBet || (isTote && bet?.bet_legs)
                  ? evenInfoFromLegs
                  : bet?.event_title ?? ''}
              </BoldText>
              {!isWincore && bet.price_type && (
                <Badge colorScheme="blackAlpha" ml="3">
                  {getPrettyPriceType(bet.price_type)}
                </Badge>
              )}
              {isWincore && bet.bet_legs && bet.bet_legs[0]?.price_type && (
                <Badge colorScheme="blackAlpha" ml="3">
                  {getPrettyPriceType(bet.bet_legs[0]?.price_type)}
                </Badge>
              )}
            </Flex>

            <Flex mb={2} flexDir="column">
              {isExoticBet &&
                bet?.selection?.map((combo, index) => (
                  <Flex key={`combo_${index}`} mt={1} w="full">
                    <Text w={75}>{getPlaceFromNumber(index + 1)}</Text>
                    <Flex>{combo?.map((runner) => runner).join(', ')}</Flex>
                  </Flex>
                ))}
            </Flex>

            {!!bet?.mbl_stake && (
              <MBLFlag>
                {Strings.Mbl} {centsToDollars(bet?.mbl_stake)}
              </MBLFlag>
            )}
          </Flex>
        </Link>
      )}
      {!isMulti && (
        <Flex alignItems="center">
          <Flex direction="row" mr={2} width={6}>
            <IconSvg
              name={getIconAssetPath(
                'sports',
                isTote && bet?.bet_legs
                  ? bet?.bet_legs && bet?.bet_legs[0]?.event_icon
                  : bet?.event_icon ?? ''
              )}
            />
          </Flex>
          <Text fontSize="sm" textTransform="capitalize">
            {isRace ? raceDetails : sportDetails}
          </Text>
        </Flex>
      )}
    </FlexWrapper>
  );
};
