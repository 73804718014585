import { Skeleton } from '@chakra-ui/react';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useQueryPunterProfile } from '../../api/punterProfile/punterProfile.hooks';
import Header from '../../features/punters/pages/Punter/components/Header';

export default function PunterHeader() {
  return (
    <>
      <HeaderWrapper />
      <Outlet />
    </>
  );
}

const HeaderWrapper = () => {
  const { punterId } = useParams();
  const { data, isLoading } = useQueryPunterProfile({
    params: { punterId },
    options: {
      refetchInterval: 0,
      staleTime: 0,
      cacheTime: 0,
    },
  });

  if (isLoading) return <Skeleton />;

  return <Header currentPunter={data} />;
};
