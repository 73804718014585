import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { centsToDollars } from '@/common/utils';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Text } from '@chakra-ui/react';
import DownloadCSVButton from '../../../DepositMatch/components/DownloadCSVButton/DownloadCSVButton';

export const UseTreasureHuntColumns = () => {
  const columns = useMemo<ColumnDef<Api['PromoSummaryResponseItem']>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => {
          const { name } = row.original;
          return name;
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: ({ row }) => {
          const { expiry, status } = row.original;
          const isActive = !expiry || dayjs(expiry).isAfter(dayjs());
          let text;
          let textColor;

          switch (status) {
            case 'cancelled':
              text = 'CANCELLED';
              textColor = 'orange';
              break;
            default:
              text = isActive ? 'ACTIVE' : 'EXPIRED';
              textColor = isActive ? 'green' : 'red';
          }

          return (
            <Text fontWeight="bold" color={textColor}>
              {text}
            </Text>
          );
        },
      },
      {
        accessorKey: 'start_time',
        header: 'Start Date/Time',
        cell: ({ row }) => {
          const { start_time } = row.original;
          return dayjs(start_time).format('DD MMM YYYY HH:mm:ss');
        },
      },
      {
        accessorKey: 'expiry',
        header: 'End Date/Time',
        cell: ({ row }) => {
          const { expiry } = row.original;
          return dayjs(expiry).format('DD MMM YYYY HH:mm:ss');
        },
      },
      {
        accessorKey: 'max_value',
        header: 'Potential Cost',
        cell: ({ row }) => {
          const { max_value } = row.original;
          return centsToDollars(max_value);
        },
      },
      {
        accessorKey: 'claimed_amount',
        header: 'Realised Cost',
        cell: ({ row }) => {
          const { claimed_amount } = row.original;
          return centsToDollars(claimed_amount);
        },
      },
      {
        accessorKey: 'total_punters',
        header: 'Number of Punters',
        cell: ({ row }) => {
          const { total_punters } = row.original;
          return total_punters;
        },
      },
      {
        accessorKey: 'promo_id',
        header: () => (
          <FormattedMessage id="promoManagerPage.actionsTableHeader" />
        ),
        cell: ({ row }) => {
          const { promo_id, name } = row.original;
          return <DownloadCSVButton promoId={promo_id} filename={name} />;
        },
      },
    ],
    []
  );

  return { columns };
};
