import React, { useEffect } from 'react';
import {
  Box,
  Icon,
  Flex,
  Text,
  IconButton,
  Select,
  Tooltip,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { InformationCircle } from '@styled-icons/ionicons-solid/InformationCircle';
import dayjs from 'dayjs';
import { Refresh } from '@styled-icons/material-outlined/Refresh';
import { enumToLocalisationsDropdown } from '../../../../../../lib/Localisations';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../common/hooks/useRedux';
import { resetPunter } from '../../slices';
import { EPunterProfileOptions } from '../../tabs/Profile/types';
import {
  getStrings,
  centsToDollars,
  getIconAssetPath,
} from '../../../../../../common/utils';
import { getPunterStats } from '../../actions';
import { EPlayerType } from '../../../../../../lib/DBModels';
import {
  usePunterCategoryProfile,
  useMutatePunterCategory,
} from '../../services/Punter.hooks';
import IconSvg from '@/components/IconSvg/IconSvg';
import { TPunterProfile } from '../../../../../../api/punterProfile/punterProfile.types';
import { PunterFlag } from '../PunterFlag/PunterFlag';
import StickyNote from '../StickyNote/StickyNote';
import StormLogoComponent from '@/assets/svgComponents/Storm';
import { CheckCircleFill, XCircleFill } from '@styled-icons/bootstrap';
import { useQueryClient } from 'react-query';
import { keys } from '@/api/api.keys';
import { ExclamationOctagonFill } from '@styled-icons/bootstrap';

const styles = {
  titles: {
    fontSize: 'xs',
    color: '#B0ECFF',
    fontWeight: 'semibold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    lineHeight: 'shorter',
  },
};

type HeaderProps = {
  currentPunter?: TPunterProfile;
};

const HeaderNew = ({ currentPunter: punter }: HeaderProps) => {
  const client = useQueryClient();
  const [{ PunterPage: strings }] = getStrings();
  const dispatch = useAppDispatch();

  const isVerified = !!punter?.verification_status;
  const openStatusPunter = punter?.account_status?.toLowerCase() === 'open';

  /**
   * This useEffect is weird but I'm bring it in from some older code.
   * We should look at reading punter stats from RQ
   * Remove the global state, most of this looks unnecessary
   * and could be useState.
   */
  useEffect(() => {
    (async () => {
      if (punter?.punter_id) {
        await dispatch(getPunterStats(punter?.punter_id));
      }
    })().catch(undefined);
  }, [punter, dispatch]);

  useEffect(() => {
    return () => {
      client.invalidateQueries([keys.punterProfile]);
      dispatch(resetPunter());
    };
  }, [client, dispatch]);

  return (
    <>
      <StickyNote />

      <Box p="4" bg="origin.500" borderRadius="6" lineHeight="normal" mb="3">
        <Flex mb="3" justifyContent="space-between">
          {/* Name/State */}
          <Box flex="1" pr="4">
            <Text
              fontSize="md"
              fontWeight="medium"
              color="white"
              textShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
            >{`${punter?.first_name} ${punter?.last_name}`}</Text>
            <Text
              fontWeight="medium"
              fontSize="xs"
              color="#B0ECFF"
              textShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
            >{`${punter?.suburb}, ${punter?.state}`}</Text>
          </Box>

          {/* Category */}
          <Category punter={punter} />

          {/* Activity */}
          <Activity />

          {/* Storm score */}
          <Flex
            gap="2"
            alignItems="center"
            px="4"
            borderLeft="1px"
            borderLeftColor="whiteAlpha.300"
            flex="1"
            justifyContent="center"
          >
            <StormLogoComponent
              color="#6FC2FF"
              filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15))"
            />

            <Box>
              <Text {...styles.titles}>
                {punter?.storm_category ||
                  strings.Header.categoryOptions.Newbie}
              </Text>
              <Text
                fontSize="lg"
                fontWeight="bold"
                textShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
                color="white"
              >
                {punter?.storm_score || strings.Header.stormScoreEmpty}
              </Text>
            </Box>
          </Flex>

          {/* Misc */}
          <Stats />
        </Flex>

        {/* 2nd row */}
        <Flex
          bg="whiteAlpha.100"
          boxShadow="0px 3px 6px 0px rgba(0, 0, 0, 0.20)"
          border="1px"
          borderColor="#6788F3"
          borderRadius="4"
          alignItems="stretch"
          justifyContent="space-between"
          h="56px"
        >
          {/* Status */}
          <Flex gap="4" px="4">
            <Flex
              align="center"
              textShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
              gap="1"
            >
              <Icon
                as={openStatusPunter ? CheckCircleFill : XCircleFill}
                color={openStatusPunter ? '#A1E500' : 'red.500'}
                filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15))"
              />
              <Text
                color={openStatusPunter ? '#A1E500' : 'red.500'}
                textTransform="uppercase"
                fontSize="2xs"
                fontWeight="bold"
              >
                {openStatusPunter
                  ? strings.Header.active
                  : strings.Header.inactive}
              </Text>
            </Flex>

            <Flex
              align="center"
              textShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
              gap="1"
            >
              <Icon
                as={isVerified ? CheckCircleFill : XCircleFill}
                color={isVerified ? '#ECC94B' : 'red.500'}
                filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15))"
              />
              <Text
                color={isVerified ? '#ECC94B' : 'red.500'}
                textTransform="uppercase"
                fontSize="2xs"
                fontWeight="bold"
              >
                {isVerified
                  ? strings.Profile.KYC.verified
                  : strings.Profile.KYC.unverified}
              </Text>
            </Flex>
          </Flex>

          {/* Flags */}
          <Flex
            px="4"
            minW="185px"
            borderLeft="1px"
            borderLeftColor="#6788F3"
            alignItems="center"
          >
            <PunterFlag punter={punter || null} />
          </Flex>

          {/* Stats */}
          <RowTwoStats />
        </Flex>
      </Box>
    </>
  );
};

// ---

type CategoryProps = {
  punter?: TPunterProfile;
};

const Category = ({ punter }: CategoryProps) => {
  const { data } = usePunterCategoryProfile();
  const handleSubmit = useMutatePunterCategory();

  const [{ PunterPage: strings }] = getStrings();
  const categoryOptions = enumToLocalisationsDropdown(
    Object.values(EPunterProfileOptions),
    strings.Header.categoryOptions
  );

  return (
    <Flex
      alignItems="center"
      gap="2"
      px="4"
      borderLeft="1px"
      borderLeftColor="whiteAlpha.300"
      flex="1"
    >
      <Text {...styles.titles}>{strings.Header.category}</Text>

      <Select
        onChange={(e) => handleSubmit(e.target.value)}
        value={
          (data?.use_storm_category && data?.storm_category) ||
          data?.bookie_category
        }
        defaultValue={EPlayerType.Newbie}
        variant="filled"
        minW="213px"
      >
        {categoryOptions.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>

      {punter?.storm_category !== undefined && (
        <IconButton
          aria-label="refresh-btn"
          onClick={() =>
            handleSubmit(punter?.storm_category || EPlayerType.Newbie, true)
          }
          variant="outline"
          borderColor="white"
          icon={<Icon as={Refresh} boxSize="5" color="white" />}
        />
      )}

      {!data?.use_storm_category && (
        <Flex
          gap="2"
          alignItems="center"
          px="2"
          borderRadius="4px"
          border="1px"
          borderColor="#6788F3"
          bg="whiteAlpha.100"
          boxShadow="0px 3px 6px 0px rgba(0, 0, 0, 0.20)"
          alignSelf="stretch"
        >
          <Icon as={ExclamationOctagonFill} color="white" boxSize="5" />
          <Text
            fontWeight="bold"
            fontSize="2xs"
            color="white"
            lineHeight="base"
          >
            {strings.Header.manualOverride}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

// ---
const Activity = () => {
  const { punterStats } = useAppSelector((state) => state.punter);

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      px="4"
      borderLeft="1px"
      borderLeftColor="whiteAlpha.300"
      flex="1"
    >
      <Text {...styles.titles} mb="0.5">
        Punter Activity
      </Text>

      <Flex gap="3" h="27px">
        {punterStats?.frequent_bet_types?.map((betType) => (
          <IconSvg
            key={`icon-${betType}`}
            name={getIconAssetPath('sports', betType)}
            sx={{
              boxSize: '5',
              maxH: '5',
              color: 'white',
              textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
              filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15))',
            }}
          />
        ))}
      </Flex>
    </Flex>
  );
};

// ---
const Stats = () => {
  const [{ PunterPage: strings }] = getStrings();
  const { punterStats } = useAppSelector((state) => state.punter);

  return (
    <>
      <Flex
        px="4"
        textAlign="center"
        borderLeft="1px"
        borderLeftColor="whiteAlpha.300"
        flex="1"
        flexDir="column"
        justifyContent="center"
      >
        <Text {...styles.titles}>{strings.Header.eligibleBonus}</Text>
        <Text
          fontSize="lg"
          fontWeight="bold"
          color="white"
          textShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
        >
          {centsToDollars(punterStats?.eligible_bonus ?? 0)}
        </Text>
      </Flex>

      <Tooltip
        mr="1"
        hasArrow
        isDisabled={
          !punterStats?.settlement_turnover &&
          !punterStats?.cash_out_turnover &&
          !punterStats?.pending_turnover &&
          !punterStats?.bonus_turnover
        }
        label={
          <UnorderedList>
            {punterStats?.settlement_turnover && (
              <ListItem>
                Settled: {centsToDollars(punterStats.settlement_turnover)}
              </ListItem>
            )}
            {punterStats?.pending_turnover && (
              <ListItem>
                Pending: {centsToDollars(punterStats.pending_turnover)}
              </ListItem>
            )}
            {punterStats?.cash_out_turnover && (
              <ListItem>
                Cashed Out: {centsToDollars(punterStats.cash_out_turnover)}
              </ListItem>
            )}

            {punterStats?.bonus_turnover && (
              <ListItem>
                Bonus: {centsToDollars(punterStats.bonus_turnover)}
              </ListItem>
            )}
          </UnorderedList>
        }
      >
        <Flex
          px="2"
          textAlign="center"
          borderLeft="1px"
          borderLeftColor="whiteAlpha.300"
          flex="1"
          flexDir="column"
          justifyContent="center"
          cursor="pointer"
        >
          <Text {...styles.titles}>
            <Icon boxSize="5" mr="1" as={InformationCircle} />
            Realised Turnover
          </Text>
          <Text
            fontSize="lg"
            fontWeight="bold"
            color="white"
            textShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
          >
            {centsToDollars(punterStats?.turnover)}
          </Text>
        </Flex>
      </Tooltip>

      <Tooltip
        mr="1"
        hasArrow
        isDisabled={
          !punterStats?.settlement_winnings &&
          !punterStats?.cash_out_winnings &&
          !punterStats?.pending_turnover
        }
        label={
          <UnorderedList>
            {punterStats?.settlement_winnings && (
              <ListItem>
                Cash: {centsToDollars(punterStats.settlement_winnings)}
              </ListItem>
            )}
            {punterStats?.cash_out_winnings && (
              <ListItem>
                Cashed Out: {centsToDollars(punterStats.cash_out_winnings)}
              </ListItem>
            )}
            {punterStats?.bonus_winnings && (
              <ListItem>
                Bonus: {centsToDollars(punterStats.bonus_winnings)}
              </ListItem>
            )}
          </UnorderedList>
        }
      >
        <Flex
          px="4"
          textAlign="center"
          borderLeft="1px"
          borderLeftColor="whiteAlpha.300"
          flex="1"
          flexDir="column"
          justifyContent="center"
          cursor="pointer"
        >
          <Text {...styles.titles}>
            <Icon boxSize="5" mr="1" as={InformationCircle} />
            Total Won/Lost
          </Text>
          <Text
            fontSize="lg"
            fontWeight="bold"
            color="white"
            textShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
          >
            {centsToDollars(punterStats?.winnings)}
          </Text>
        </Flex>
      </Tooltip>
    </>
  );
};

// 2nd row redux
const RowTwoStats = () => {
  const [{ PunterPage: strings }] = getStrings();
  const { punterStats } = useAppSelector((state) => state.punter);

  return (
    <>
      <Flex
        textAlign="center"
        px="4"
        flex="1"
        flexDir="column"
        justifyContent="center"
        borderLeft="1px"
        borderLeftColor="#6788F3"
      >
        <Text {...styles.titles}>{strings.Header.currentBalance}</Text>
        <Text
          color="white"
          fontSize="sm"
          fontWeight="medium"
          textShadow="0 2px 4px rgba(0, 0, 0, 0.15)"
        >
          {centsToDollars(punterStats?.balance)}
        </Text>
      </Flex>
      <Flex
        textAlign="center"
        px="4"
        flex="1"
        flexDir="column"
        justifyContent="center"
        borderLeft="1px"
        borderLeftColor="#6788F3"
      >
        <Text {...styles.titles}>Withdrawable Balance</Text>
        <Text
          color="white"
          fontSize="sm"
          fontWeight="medium"
          textShadow="0 2px 4px rgba(0, 0, 0, 0.15)"
        >
          {centsToDollars(punterStats?.unrestricted_balance)}
        </Text>
      </Flex>
      <Flex
        textAlign="center"
        px="4"
        flex="1"
        flexDir="column"
        justifyContent="center"
        borderLeft="1px"
        borderLeftColor="#6788F3"
      >
        <Text {...styles.titles}>Non-Withdrawable Balance</Text>
        <Text
          color="white"
          fontSize="sm"
          fontWeight="medium"
          textShadow="0 2px 4px rgba(0, 0, 0, 0.15)"
        >
          {centsToDollars(punterStats?.restricted_balance)}
        </Text>
      </Flex>

      <Flex
        textAlign="center"
        px="4"
        borderLeft="1px"
        borderLeftColor="#6788F3"
        flex="1"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <Text {...styles.titles}>{strings.Header.bonusBalance}</Text>
        <Text
          color="white"
          fontSize="sm"
          fontWeight="medium"
          textShadow="0 2px 4px rgba(0, 0, 0, 0.15)"
        >
          {centsToDollars(punterStats?.bonus_balance)}
        </Text>
      </Flex>

      <Tooltip
        hasArrow
        label={
          <>
            <Text>
              All Bonus Bets - {centsToDollars(punterStats?.bonus_given)}
            </Text>
            <Text>
              Bet Boost Bonus - {centsToDollars(punterStats?.bet_boost_amount)}
            </Text>
            {!!punterStats?.money_back_amount && (
              <Text>
                Money Back Bonus -{' '}
                {centsToDollars(punterStats?.money_back_amount)}
              </Text>
            )}

            <Text>
              Odds Boost -{' '}
              {centsToDollars(
                (punterStats?.odds_boost_win_new_amount ?? 0) -
                  (punterStats?.odds_boost_win_original_amount ?? 0)
              )}
            </Text>

            <Text>
              Auto Deposit Match Bonus -{' '}
              {centsToDollars(punterStats?.deposit_match_token_claimed_amount)}
            </Text>

            <Text>
              Bet Slip Bonus -{' '}
              {centsToDollars(punterStats?.bet_slip_bonus_amount)}
            </Text>

            <Text>
              Treasure Hunt Bonus -{' '}
              {centsToDollars(punterStats?.treasure_hunt_amount)}
            </Text>
          </>
        }
      >
        <Flex
          textAlign="center"
          px="4"
          borderLeft="1px"
          borderLeftColor="#6788F3"
          flex="1"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
        >
          <Text {...styles.titles}>
            <Icon boxSize="5" mr="1" as={InformationCircle} />
            Gratuities
          </Text>
          <Text
            color="white"
            fontSize="sm"
            fontWeight="medium"
            textShadow="0 2px 4px rgba(0, 0, 0, 0.15)"
          >
            {centsToDollars(
              (punterStats?.bonus_given ?? 0) +
                (punterStats?.odds_boost_win_new_amount ?? 0) -
                (punterStats?.odds_boost_win_original_amount ?? 0)
            )}
          </Text>
        </Flex>
      </Tooltip>

      <Flex
        textAlign="center"
        px="4"
        borderLeft="1px"
        borderLeftColor="#6788F3"
        flex="1"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <Text {...styles.titles}>
          {strings.Header.lastBet}{' '}
          {punterStats?.last_bet_date ? (
            <Text as="span" fontSize="2xs" fontWeight="medium" color="#ECC94B">
              {dayjs(punterStats.last_bet_date).format('DD/MM/YYYY')}
            </Text>
          ) : undefined}
        </Text>
        <Text
          color="white"
          fontSize="sm"
          fontWeight="medium"
          textShadow="0 2px 4px rgba(0, 0, 0, 0.15)"
        >
          {centsToDollars(punterStats?.last_bet || 0)}
        </Text>
      </Flex>

      <Flex
        textAlign="center"
        px="4"
        borderLeft="1px"
        borderLeftColor="#6788F3"
        flex="1"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <Text {...styles.titles}>{strings.Header.avgBet}</Text>
        <Text
          color="white"
          fontSize="sm"
          fontWeight="medium"
          textShadow="0 2px 4px rgba(0, 0, 0, 0.15)"
        >
          {centsToDollars(punterStats?.average_bet)}
        </Text>
      </Flex>

      <Flex
        textAlign="center"
        px="4"
        borderLeft="1px"
        borderLeftColor="#6788F3"
        flex="1"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <Text {...styles.titles}>{strings.Header.betsPlaced}</Text>
        <Text
          color="white"
          fontSize="sm"
          fontWeight="medium"
          textShadow="0 2px 4px rgba(0, 0, 0, 0.15)"
        >
          {punterStats?.total_bets}
        </Text>
      </Flex>
    </>
  );
};

export default HeaderNew;
