import { object, SchemaOf, string } from 'yup';
import { TBookiePassword, TBookieProfile } from '../types';
import { getStrings } from '../../../../../../../common/utils';
import { TPasswordStrengthTests } from '../../../../../../../common/components/PasswordStrength/Services/Config.PasswordStrength';
import { isSweeps } from '@/configs/brands.config';

const [
  {
    FormValidation: { RequiredField: RequiredFieldMessage },
    SettingsPage: { YourProfile },
  },
] = getStrings();

export const BookieProfileDefault: TBookieProfile = {
  id: '',
  email: '',
  first_name: '',
  last_name: '',
  mobile: '',
  licensed_state: '',
  license_id: '',
};

export const BookieProfileValidation: SchemaOf<TBookieProfile> = object({
  id: string().required(RequiredFieldMessage),
  email: string().email().required(RequiredFieldMessage),
  first_name: string().required(RequiredFieldMessage),
  last_name: string().required(RequiredFieldMessage),
  mobile: string().required(RequiredFieldMessage),
  licensed_state: isSweeps
    ? string().notRequired()
    : string().required(RequiredFieldMessage),
  license_id: isSweeps
    ? string().notRequired()
    : string().required(RequiredFieldMessage),
});

export const BookiePasswordInitialValues: TBookiePassword = {
  current_password: '',
  new_password: '',
};

export const BookiePasswordValidation: SchemaOf<TBookiePassword> = object({
  current_password: string()
    .required(RequiredFieldMessage)
    .test(
      'password-tests',
      YourProfile.ChangePassword.validation.rules,
      (value) =>
        value !== null &&
        value !== undefined &&
        TPasswordStrengthTests.every((test) => test.regex.test(value))
    ),
  new_password: string().required(
    YourProfile.ChangePassword.validation.required
  ),
});
