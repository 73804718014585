import React, { useMemo, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Divider,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tag,
} from '@chakra-ui/react';
import { useLocation, Link, Path } from 'react-router-dom';
import { useAppSelector } from '@/common/hooks/useRedux';
import { useQueryBetsForToteMulti } from '@/api/tradeManager/raceDetails/betsForExotics/betsForExotics.hooks';
import { BetsTable } from '@/common/components';
import { ColumnDef } from '@tanstack/react-table';
import { TMarketBet } from '@/api/tradeManager/trademanager.types';
import { TOTE_MULTI_DISPLAY_NAME, centsToDollars } from '@/common/utils';
import BetStatusBadge from '@/common/components/BetStatusBadge';
import { Pagination } from '@/common/components/Pagination/Pagination';

export function ToteMulti() {
  const [offset, setOffset] = useState(0);

  const { raceData, toteMultiResults } = useAppSelector(
    (state) => state.raceDetails
  );
  const limit = 20;

  const { data: bets, isLoading } = useQueryBetsForToteMulti({
    params: { limit, race_id: raceData.race_id },
    options: { enabled: !!raceData.race_id },
  });

  const location = useLocation();

  const columns = useMemo<ColumnDef<TMarketBet>[]>(
    () => [
      {
        accessorKey: 'punter',
        header: 'Punter',
        cell: ({ row }) => {
          const {
            original: { bet_punter_name, bet_punter_id },
          } = row;
          return (
            <Link
              to={
                {
                  pathname: `/punter/${bet_punter_id}`,
                  state: { from: location },
                } as unknown as Path
              }
            >
              {bet_punter_name ?? ''}
            </Link>
          );
        },
      },
      {
        accessorKey: 'mystery_bet_bets',
        header: 'Type',
        enableSorting: false,
        cell: ({ row }) => {
          const {
            original: { proposition },
          } = row;
          return <Text>{TOTE_MULTI_DISPLAY_NAME[proposition ?? 0]}</Text>;
        },
      },
      {
        header: 'Bet Status',
        cell: ({ row }) => {
          const {
            original: { bet_status },
          } = row;

          return <BetStatusBadge status={bet_status} />;
        },
      },

      {
        header: 'Odds',
        cell: ({ row }) => {
          const {
            original: { bet_odds },
          } = row;
          return <Text>{bet_odds?.toFixed(2) ?? 'N/A'}</Text>;
        },
      },

      {
        header: 'Stake',
        cell: ({ row }) => {
          const {
            original: { bet_stake },
          } = row;
          return <Text>{centsToDollars(bet_stake)}</Text>;
        },
      },
      {
        header: 'Potential Return',
        cell: ({ row }) => {
          const {
            original: { potential_return },
          } = row;
          return <Box>{centsToDollars(potential_return)}</Box>;
        },
      },
      {
        header: 'Actual Return',
        cell: ({ row }) => {
          const {
            original: { actual_return },
          } = row;
          return <Box>{centsToDollars(actual_return)}</Box>;
        },
      },
    ],
    [location]
  );

  if (isLoading) return 'Loading...';

  const isLastFetch = (bets?.length ?? 0) < limit;

  return (
    <>
      <Divider />

      {!!toteMultiResults && (
        <Box mb="5">
          <Text
            color="origin.500"
            fontWeight="normal"
            fontSize="lg"
            px="5"
            pt="2"
          >
            Results
          </Text>

          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Type</Th>
                  <Th>Selections</Th>
                  <Th isNumeric>Dividends</Th>
                </Tr>
              </Thead>
              <Tbody>
                {toteMultiResults.map((result, index) => (
                  <Tr key={index}>
                    <Td>{TOTE_MULTI_DISPLAY_NAME[result.type ?? '']}</Td>
                    <Td>
                      <Flex gap="2">
                        {result.legs.map((leg, idx) => (
                          <Flex
                            key={idx}
                            sx={{ flexDir: 'column', w: 'fit-content' }}
                          >
                            <Tag
                              borderBottomRadius="0"
                              bg="gray.500"
                              color="white"
                            >
                              R{leg}
                            </Tag>{' '}
                            <Tag borderTopRadius="0" justifyContent="center">
                              {result.selection[idx]}
                            </Tag>
                          </Flex>
                        ))}
                      </Flex>
                    </Td>
                    <Td isNumeric>{result.dividend.toFixed(2)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Box>
        <BetsTable columns={columns} data={bets ?? []} loading={isLoading} />

        <Pagination
          nextDisabled={isLastFetch}
          offset={offset}
          onPageChange={(newOffset) => setOffset(newOffset)}
        />
      </Box>
    </>
  );
}
