import React from 'react';
import { Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useColumns, useData } from '../Services/Hooks.BetsForProposition';
import { useBetsForMarket } from '../../../../../api/tradeManager/match/match.hooks';
import TableChakra from '../../../../../common/components/TableChakra/TableChakra';
import {
  EEventType,
  ERaceMarketType,
} from '../../../pages/RaceDetails/Services/RaceDetails.types';
import VoidBetModal from '@/features/bets/modals/VoidBetModal';
import { Pagination } from '@/common/components/Pagination/Pagination';
import { TMarketBet } from '@/api/tradeManager/trademanager.types';
import { PaginationV2 } from '@/common/components/Pagination/PaginationV2';

export interface TTableProps {
  raceRunnerId?: string;
  raceId?: string;

  marketId?: string;
  marketPropositionId?: string;

  type: EEventType;
  priceType?: ERaceMarketType;
}

const Table: React.FC<TTableProps> = ({
  raceRunnerId,
  marketId,
  marketPropositionId,
  type,
  raceId,
  priceType,
}) => {
  const { columns } = useColumns();
  const {
    bets,
    exoticsBets,
    loading,
    loadData,
    isExotics,
    offset,
    setOffset,
    isLastData,
    next: onNext,
    previous: onPrev,
    previousDisabled,
    nextDisabled,
    hidePagination,
  } = useData(type, raceId, raceRunnerId, priceType);

  const { data, isLoading } = useBetsForMarket(marketId ?? '');

  const betsForMarket = data?.filter(
    (bet) => bet.proposition_id === marketPropositionId
  );
  const isRaceMarket = type === EEventType.Race;
  const relatedBets = isRaceMarket ? bets : betsForMarket;

  if (!betsForMarket && !exoticsBets && !bets) return null;

  return (
    <>
      <VoidBetModal
        onVoided={() => {
          if (isExotics) return loadData();
        }}
      />
      <TableChakra<TMarketBet>
        columns={columns}
        data={isExotics ? exoticsBets ?? [] : relatedBets ?? []}
        isLoading={isExotics ? loading : isLoading}
        placeholder={
          <Text py="3">
            <FormattedMessage id="trademanagerpage.betsforrunner.noBets" />
          </Text>
        }
      />
      {/* This is a new pagination component that doesn't contain any logic, it is used solely as a view
          The reason for this is that this component should ONLY render itself, any business logic needs to be
          ouside so that this component is reusable.
      */}
      {!isExotics && (
        <PaginationV2
          {...{
            onNext,
            onPrev,
            hidePagination,
            nextDisabled,
            previousDisabled,
          }}
        />
      )}
      {isExotics && (
        <Pagination
          nextDisabled={isLastData}
          offset={offset}
          onPageChange={(newOffset) => setOffset(newOffset)}
        />
      )}
    </>
  );
};

export default Table;
