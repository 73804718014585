import React, { ReactNode } from 'react';
import { Form, Formik } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import { useBanner } from '../../services/Banner.hooks';
import {
  useMutationAddBanner,
  useMutationUploadMedia,
} from '@/api/promoManager/banners/banner.hooks';
import validationSchema from './Form.validation';
import { v4 as uuid } from 'uuid';
import { useBanners } from '../../../../services/Banners.hooks';
import { useToast } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import {
  EDisplay,
  EStatus,
  TAddBannerParams,
  TData,
  TUploadMediaData,
} from '@/api/promoManager/banners/banner.types';

type TFormikHOC = {
  children: ReactNode;
};

export type TFormikValues = TData;

export default function FormHOC({ children }: TFormikHOC) {
  const { bannerId } = useParams();
  const { bannerToShow, isLoading } = useBanner({ id: bannerId });
  const { data: bannerList } = useBanners();
  const { mutateAsync } = useMutationAddBanner();
  const { mutateAsync: mutateMedia } = useMutationUploadMedia();
  const toast = useToast();
  const intl = useIntl();
  const navigate = useNavigate();

  if (isLoading) return <></>;

  const initialValues = {
    id: bannerToShow?.id ?? uuid(),
    title: bannerToShow?.title ?? '',
    media: {
      desktop: {
        name: `${uuid()}-desktop`,
        base64: '',
        publicUrl: bannerToShow?.media?.desktop?.publicUrl,
        isTwoLayer: !!bannerToShow?.media?.desktop?.layerLink,
        layerName: `${uuid()}-layer-desktop`,
        animatedLayerBase64: bannerToShow?.media?.desktop?.layerLink,
        link: bannerToShow?.media?.desktop?.link,
      },
      mobile: {
        name: `${uuid()}-mobile`,
        layerName: `${uuid()}-layer-mobile`,
        base64: '',
        publicUrl: bannerToShow?.media?.mobile?.publicUrl,
        isTwoLayer: !!bannerToShow?.media?.mobile?.layerLink,
        animatedLayerBase64: bannerToShow?.media?.mobile?.layerLink,
        link: bannerToShow?.media?.mobile?.link,
      },
      app: {
        name: `${uuid()}-app`,
        layerName: `${uuid()}-layer-app`,
        base64: '',
        publicUrl: bannerToShow?.media?.app?.publicUrl,
        isTwoLayer: !!bannerToShow?.media?.app?.layerLink,
        animatedLayerBase64: bannerToShow?.media?.app?.layerLink,
      },
    },
    location: bannerToShow?.location ?? [],
    punter_category: bannerToShow?.punter_category ?? [],
    display: bannerToShow?.display ?? EDisplay.Both,
    status: bannerToShow?.status ?? EStatus.Draft,
    start_date: bannerToShow?.start_date,
    end_date: bannerToShow?.end_date,
    platform: bannerToShow?.platform ?? 'web',
    has_money_back_promo: bannerToShow?.has_money_back_promo ?? false,
    money_back_promo_id: bannerToShow?.money_back_promo_id ?? '',
    has_treasure_hunt_promo: bannerToShow?.has_treasure_hunt_promo ?? false,
    treasure_hunt_promo_id: bannerToShow?.treasure_hunt_promo_id ?? '',
  };

  return (
    <Formik<TFormikValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={async (val, { setSubmitting }) => {
        const desktopName = `${uuid()}-desktop`;
        const desktopNameAnimated = `${uuid()}-desktop-animated`;
        const mobileName = `${uuid()}-mobile`;
        const mobileNameAnimated = `${uuid()}-mobile-animated`;
        const appName = `${uuid()}-app`;

        async function urlToBase64(url: string): Promise<string> {
          const response = await fetch(url);
          const blob = await response.blob();

          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64String = (reader.result as string).split(',')[1];
              resolve(base64String);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });
        }

        const desktopBase64 = await urlToBase64(
          bannerToShow?.media?.desktop?.publicUrl ?? ''
        );
        const desktopLayerBase64 = await urlToBase64(
          bannerToShow?.media?.desktop?.layerLink ?? ''
        );
        const mobileBase64 = await urlToBase64(
          bannerToShow?.media?.mobile?.publicUrl ?? ''
        );
        const mobileLayerBase64 = await urlToBase64(
          bannerToShow?.media?.mobile?.layerLink ?? ''
        );
        const appBase64 = await urlToBase64(
          bannerToShow?.media?.app?.publicUrl ?? ''
        );

        const getMediaObject = (
          name: string,
          contentBase64: string | undefined
        ) => ({
          name,
          contentBase64,
          contentType: 'image/png',
        });

        const mediaObjects = [
          getMediaObject(
            desktopName,
            val?.media?.desktop?.publicUrl?.split(',')[1] ?? desktopBase64
          ),
          getMediaObject(
            mobileName,
            val?.media?.mobile?.publicUrl?.split(',')[1] ?? mobileBase64
          ),
          getMediaObject(
            appName,
            val?.media?.app?.publicUrl?.split(',')[1] ?? appBase64
          ),
        ];

        if (
          val?.media?.desktop?.animatedLayerBase64 &&
          val?.media.desktop.isTwoLayer
        ) {
          mediaObjects.push(
            getMediaObject(
              desktopNameAnimated,
              val?.media?.desktop?.animatedLayerBase64?.split(',')[1] ??
                desktopLayerBase64
            )
          );
        }
        if (
          val?.media?.mobile?.animatedLayerBase64 &&
          val?.media.mobile.isTwoLayer
        ) {
          mediaObjects.push(
            getMediaObject(
              mobileNameAnimated,
              val?.media?.mobile?.animatedLayerBase64?.split(',')[1] ??
                mobileLayerBase64
            )
          );
        }

        if (val?.media?.app?.base64) {
          mediaObjects.push(
            getMediaObject(
              `${uuid()}-app`,
              val?.media?.app?.base64?.split(',')[1]
            )
          );
        }

        mutateMedia(mediaObjects as TAddBannerParams[]).then(
          (data: TUploadMediaData) => {
            const desktop = data.find((e) => e?.name === desktopName);
            const mobile = data.find((e) => e?.name === mobileName);
            const app = data.find((e) => e?.name === appName);

            const webLinks: TFormikValues = {
              ...val,
              platform: 'web',
              media: {
                desktop: {
                  publicUrl: desktop?.publicUrl,
                  name: `${uuid()}-desktop`,
                  link: val.media.desktop?.link,
                },
                mobile: {
                  publicUrl: mobile?.publicUrl,
                  link: val.media.desktop?.link,
                  name: `${uuid()}-mobile`,
                },
                app: {
                  publicUrl: app?.publicUrl,
                  link: val.media.app?.link,
                  name: `${uuid()}-app`,
                },
              },
            };

            const appLinks: TFormikValues = {
              ...val,
              platform: 'app',
              id: uuid(),
              media: {
                app: {
                  publicUrl: app?.publicUrl,
                  name: `${uuid()}-app`,
                },
              },
            };

            const getDataArray = () => {
              if (val.platform === 'both') return [webLinks, appLinks];
              return val.platform === 'web' ? [webLinks] : [appLinks];
            };

            const mergedArray = getDataArray().concat(
              bannerList?.filter((l) => l.id !== val.id) ?? []
            ); // Merge the arrays
            const base64String = btoa(JSON.stringify(mergedArray));

            mutateAsync({
              contentBase64: base64String,
              contentType: 'application/json',
              version: Number(uuid()),
            }).then(() => {
              toast({
                title: intl.formatMessage({
                  id: 'promomanagerpage.banner.toast.success',
                }),
                status: 'success',
                position: 'top-right',
                duration: 5000,
              });
              navigate(`/promo-manager/banners/`);
            });
          }
        );

        setSubmitting(true);
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
