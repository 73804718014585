import { ENV } from '@/lib/Constants';

/**
 * Feature flags that can be used across the whole site.
 *
 * If a feature is in active development but not ready to release, we can
 * scope it so that its only visible locally using `isInDevelopment` variable.
 *
 * Feature flags should be cleaned up as soon as the feature is complete.
 */
const { NODE_ENV } = ENV;
const isInDevelopment = NODE_ENV === 'development' || NODE_ENV === 'test';
const isInDev = window.location.hostname === 'portal.dev.betcloud.dev';
const isInQat = window.location.hostname === 'portal.qat.betcloud.dev';
const isInPerf = window.location.hostname === 'portal.perf.betcloud.dev';
const isInBat = window.location.hostname === 'portal.bat.betcloud.dev';
const isStaging = window.location.hostname === 'portal.staging.betcloud.dev';
const isHotfix = window.location.hostname === 'portal.hotfix.betcloud.dev';
const isInStaging = [
  'portal.staging.release.betcloud.dev',
  'portal.staging.hotfix.betcloud.dev',
].includes(window.location.hostname);

const isInIAL = window.location.hostname.includes('ial.');

export const FEATURE_FLAGS = {
  MAINTANANCE_COMPLETED: false,
  FOR_P2: false,
  HAS_EXOTICS: isInDevelopment,
  IS_REGELATOR: isInIAL,
  IS_QAT: isInQat,
  IS_DEV: isInDev || isInDevelopment,
  IS_PERF: isInPerf,
  IS_BAT: isInBat,
  IS_IN_STAGING: isInStaging,
  IS_STAGING: isStaging,
  IS_HOTFIX: isHotfix,
  IS_SGM_ENABLED: true,
  IS_SRM_ENABLED: true,
  IS_BLENDED_ENABLED: true,
  IS_CREATING_CUSTOM_MARKETS_IN_CORE_ENABLED: false,
  IS_PROMO_RECOVERY_ENABLED: false,
  IS_MONEY_BACK_ENABLED: true,
  IS_TREASURE_HUNT_ENABLED: true,
  IS_MYSTERY_BET_ENABLED: true,
  IS_MOM_ENABLED: isInDevelopment || isInDev || isInQat,
  IS_DASHBOARD_ENABLED: isInDevelopment || isInDev || isInQat,
  IS_IN_PLAY_ENABLED: isInDevelopment || isInDev,
};
