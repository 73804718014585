import { centsToDollars, oddsFormatted } from '@/common/utils';
import { TDashboardFilterStates } from '../Dashboard';
import {
  TByPunterCategory,
  TDashboardDataResponse,
  TDashboardQueryParams,
  TTransactionStatsResponse,
} from '../api/api';
import { StyledIcon } from 'styled-icons/types';
import { Users } from '@styled-icons/heroicons-solid/Users';
import { Bank } from '@styled-icons/remix-line/Bank';
import { DollarCircle } from '@styled-icons/boxicons-solid/DollarCircle';
import { ReceiptMoney } from '@styled-icons/fluentui-system-regular/ReceiptMoney';
import { HandCoin } from '@styled-icons/remix-line/HandCoin';
import { Newspaper } from '@styled-icons/remix-fill/Newspaper';
import { UserCircle } from '@styled-icons/heroicons-solid/UserCircle';
import { PeopleMoney } from '@styled-icons/fluentui-system-regular/PeopleMoney';
import { Rocket } from '@styled-icons/octicons/Rocket';
import { Gift } from '@styled-icons/bootstrap/Gift';
import { AutoFixHigh } from '@styled-icons/material/AutoFixHigh';
import { GiftCard } from '@styled-icons/fluentui-system-filled/GiftCard';

export function formatDashboardQueryParams(
  queryParams: TDashboardFilterStates,
  withPC: boolean
): TDashboardQueryParams {
  return {
    start_time: queryParams.startTimestamp,
    end_time: queryParams.endTimestamp,
    ...(queryParams.punterCategory[0] !== 'All' && withPC
      ? { punter_category: queryParams.punterCategory }
      : {}),
    ...(queryParams.betType[0] !== 'All'
      ? { bet_type: queryParams.betType }
      : {}),
    ...(queryParams.eventType[0] !== 'All'
      ? { event_type: queryParams.eventType }
      : {}),
    ...(queryParams.region[0] !== 'All' ? { region: queryParams.region } : {}),
    ...(queryParams.code[0] !== 'All' ? { code: queryParams.code } : {}),
    ...(queryParams.raceType[0] !== 'All'
      ? { race_type: queryParams.raceType }
      : {}),
    ...(queryParams.sportId[0] !== 'All'
      ? { sport_id: queryParams.sportId }
      : {}),
  };
}

export type TRealMoneyAndBonusBetsItem = {
  title: string;
  icon?: StyledIcon;
  colours?: string[];
  count: number | null;
  percentageCount?: number | null;
  value: string[] | string | number | null;
  percentageValue: number | null;
  isBonusData?: boolean;
};

export type TRealMoneyAndBonusBets = {
  realMoney: TRealMoneyAndBonusBetsItem[];
  bonusBets: TRealMoneyAndBonusBetsItem[];
  byPunterCategory: TByPunterCategory[];
};

export function normalizePayload(
  payload: TDashboardDataResponse
): TRealMoneyAndBonusBets {
  return {
    realMoney: [
      {
        title: 'Bets Placed',
        count: null,
        percentageCount: null,
        value: payload.real_money.bets_placed_count.value.toString(),
        percentageValue: payload.real_money.bets_placed_count.percentage_change,
      },
      {
        title: 'Active Bets',
        count: payload.real_money.active_bets_count.value,
        percentageCount: payload.real_money.active_bets_count.percentage_change,
        value: centsToDollars(payload.real_money.active_bets_stake.value).split(
          '.'
        ),
        percentageValue: payload.real_money.active_bets_stake.percentage_change,
      },
      {
        title: 'Settled Bets',
        count: payload.real_money.bets_settled_count.value,
        percentageCount:
          payload.real_money.bets_settled_count.percentage_change,
        value: centsToDollars(
          payload.real_money.bets_settled_payout.value
        ).split('.'),
        percentageValue:
          payload.real_money.bets_settled_payout.percentage_change,
      },
      { title: 'Turnover', count: null, percentageValue: null, value: '' },
      {
        title: 'Profit',
        count: null,
        percentageCount: null,
        value: centsToDollars(payload.real_money.profit.value).split('.'),
        percentageValue: payload.real_money.profit.percentage_change,
      },
      {
        title: 'Margin',
        count: null,
        percentageCount: null,
        value: `${payload.real_money.margin.value}%`,
        percentageValue: payload.real_money.margin.percentage_change,
      },
    ],
    bonusBets: [
      {
        title: 'Bets Placed',
        count: null,
        percentageCount: null,
        value: payload.bonus_bet.bets_placed_count.value.toString(),
        percentageValue: payload.bonus_bet.bets_placed_count.percentage_change,
      },
      {
        title: 'Active Bets',
        count: payload.bonus_bet.active_bets_count.value,
        percentageCount: payload.bonus_bet.active_bets_count.percentage_change,
        value: centsToDollars(payload.bonus_bet.active_bets_stake.value).split(
          '.'
        ),
        percentageValue: payload.bonus_bet.active_bets_stake.percentage_change,
      },
      {
        title: 'Settled Bets',
        count: payload.bonus_bet.bets_settled_count.value,
        percentageCount: payload.bonus_bet.bets_settled_count.percentage_change,
        value: centsToDollars(
          payload.bonus_bet.bets_settled_payout.value
        ).split('.'),
        percentageValue:
          payload.bonus_bet.bets_settled_payout.percentage_change,
      },
      { title: 'Turnover', count: null, percentageValue: null, value: '' },
      {
        title: 'Profit',
        count: null,
        percentageCount: null,
        value: centsToDollars(payload.bonus_bet.profit.value).split('.'),
        percentageValue: payload.bonus_bet.profit.percentage_change,
      },
      {
        title: 'Margin',
        count: null,
        percentageCount: null,
        value: `${payload.bonus_bet.margin.value}%`,
        percentageValue: payload.bonus_bet.margin.percentage_change,
      },
    ],
    byPunterCategory: [
      ...payload.by_punter_category.map((category) => ({
        punter_category: category.punter_category,
        real_money: {
          bets_placed_count: category.real_money.bets_placed_count,
          bets_placed_stake: category.real_money.bets_placed_stake,
          active_bets_count: category.real_money.active_bets_count,
          active_bets_stake: category.real_money.active_bets_stake,
          average_bet_stake: category.real_money.average_bet_stake,
          bets_settled_count: category.real_money.bets_settled_count,
          bets_settled_stake: category.real_money.bets_settled_stake,
          bets_settled_payout: category.real_money.bets_settled_payout,
          profit: category.real_money.profit,
          margin: category.real_money.margin,
        },
        bonus_bet: {
          bets_placed_count: category.bonus_bet.bets_placed_count,
          bets_placed_stake: category.bonus_bet.bets_placed_stake,
          active_bets_count: category.bonus_bet.active_bets_count,
          active_bets_stake: category.bonus_bet.active_bets_stake,
          bets_settled_count: category.bonus_bet.bets_settled_count,
          bets_settled_stake: category.bonus_bet.bets_settled_stake,
          bets_settled_payout: category.bonus_bet.bets_settled_payout,
          profit: category.bonus_bet.profit,
          margin: category.bonus_bet.margin,
        },
      })),
    ],
  };
}

export function normalizeTransactionResponse(
  response?: TTransactionStatsResponse
): TRealMoneyAndBonusBetsItem[] {
  return [
    {
      title: 'New Punters',
      colours: ['#28c76f', '#324b50'],
      count: null,
      icon: Users,
      value: 'TBD' ?? '',
      percentageValue: 0,
    },
    {
      title: 'Average Bet',
      icon: Newspaper,
      count: null,
      value: centsToDollars(response?.average_bet_stake?.value),
      percentageValue: response?.average_bet_stake?.percentage_change ?? null,
    },
    {
      title: 'Deposits',
      colours: ['#00bad1', '#30495f'],
      count: response?.deposit_count.value ?? null,
      icon: Bank,
      value: response?.deposit_amount.value ?? 0,
      percentageValue: response?.deposit_amount.percentage_change ?? null,
    },
    {
      title: 'First Deposits',
      colours: ['#ff9f43', '#51454b'],
      icon: DollarCircle,
      count: response?.first_deposit_count.value ?? null,
      value: centsToDollars(response?.first_deposit_amount.value ?? 0),
      percentageValue: response?.first_deposit_amount.percentage_change ?? null,
    },
    {
      title: 'Active Punters',
      colours: ['#00bad1', '#30495f'],
      icon: UserCircle,
      count: null,
      value: response?.new_punters.toString() ?? '',
      percentageValue: null,
    },
    {
      title: 'Withdrawals',
      colours: ['#ff9f43', '#51454b'],
      icon: ReceiptMoney,
      count: response?.withdrawal_count.value ?? null,
      value: response?.withdrawal_amount.value ?? 0,
      percentageValue: response?.withdrawal_amount.percentage_change ?? null,
    },
    {
      title: 'Average Deposit',
      colours: ['#28c76f', '#324b50'],
      icon: PeopleMoney,
      count: null,
      value: centsToDollars(response?.average_deposit_amount.value),
      percentageValue:
        response?.average_deposit_amount.percentage_change ?? null,
    },
    {
      title: 'First Bets',
      icon: HandCoin,
      count: null,
      value: 'TBD',
      percentageValue: null,
    },
    {
      title: 'Bet Boost',
      colours: ['#ff9f43', '#51454b'],
      icon: Rocket,
      count: response?.bet_boost_count.value ?? null,
      value: centsToDollars(response?.bet_boost_amount.value),
      percentageValue: response?.bet_boost_amount.percentage_change ?? null,
      isBonusData: true,
    },
    {
      title: 'Betslip Bonus',
      colours: ['#28c76f', '#324b50'],
      icon: Gift,
      count: response?.bet_slip_bonus_count.value ?? null,
      value: centsToDollars(response?.bet_slip_bonus_amount.value),
      percentageValue:
        response?.bet_slip_bonus_amount.percentage_change ?? null,
      isBonusData: true,
    },
    {
      title: 'Treasure Hunt',
      icon: HandCoin,
      count: response?.treasure_hunt_count.value ?? null,
      value: centsToDollars(response?.treasure_hunt_amount.value),
      percentageValue: response?.treasure_hunt_amount.percentage_change ?? null,
      isBonusData: true,
    },
    {
      title: 'Auto Deposit Match',
      colours: ['#00bad1', '#30495f'],
      icon: AutoFixHigh,
      count: response?.auto_deposit_match_count.value ?? null,
      value: centsToDollars(response?.auto_deposit_match_amount.value),
      percentageValue:
        response?.auto_deposit_match_amount.percentage_change ?? null,
      isBonusData: true,
    },
    {
      title: 'Deposit Match',
      colours: ['#00bad1', '#30495f'],
      icon: DollarCircle,
      count: response?.deposit_match_count.value ?? null,
      value: centsToDollars(response?.deposit_match_amount.value),
      percentageValue: response?.deposit_match_amount.percentage_change ?? null,
      isBonusData: true,
    },
    {
      title: 'Money Back',
      icon: HandCoin,
      count: response?.money_back_count.value ?? null,
      value: centsToDollars(response?.money_back_amount.value),
      percentageValue: response?.money_back_amount.percentage_change ?? null,
      isBonusData: true,
    },
    {
      title: 'Other Bonus',
      colours: ['#ff9f43', '#51454b'],
      icon: GiftCard,
      count: response?.other_bonus_count.value ?? null,
      value: centsToDollars(response?.other_bonus_amount.value),
      percentageValue: response?.other_bonus_amount.percentage_change ?? null,
      isBonusData: true,
    },
    {
      title: 'All Bonus Turnover',
      colours: ['#ff9f43', '#51454b'],
      icon: GiftCard,
      count: null,
      value: centsToDollars(response?.all_bonus_amount.value ?? 0),
      percentageValue:
        response?.all_bonus_percentage_of_turnover.percentage_change ?? null,
      isBonusData: true,
    },
    {
      title: 'Auto Bonus Turnover',
      colours: ['#ff9f43', '#51454b'],
      icon: GiftCard,
      count: null,
      value: `${centsToDollars(
        response?.auto_bonus_amount.value
      )} | ${oddsFormatted(
        response?.auto_bonus_percentage_of_turnover.value ?? 0
      )}%`,
      percentageValue:
        response?.auto_bonus_percentage_of_turnover.percentage_change ?? null,
      isBonusData: true,
    },
    {
      title: 'Ad Hoc (Manual) Bonus Turnover',
      colours: ['#ff9f43', '#51454b'],
      icon: GiftCard,
      count: null,
      value: `${centsToDollars(
        response?.manual_bonus_amount.value
      )} | ${oddsFormatted(
        response?.manual_bonus_percentage_of_turnover.value ?? 0
      )}%`,
      percentageValue:
        response?.manual_bonus_percentage_of_turnover.percentage_change ?? null,
      isBonusData: true,
    },
    {
      title: 'Total Balance',
      colours: ['#ff9f43', '#51454b'],
      icon: GiftCard,
      count: null,
      value: centsToDollars(response?.total_balance.value) ?? '',
      percentageValue: response?.total_balance.percentage_change ?? null,
      isBonusData: true,
    },
  ];
}
