import { TPlatformType } from '@/features/promoManager/pages/PromoManager/tabs/Banners/Banners';
import { DeepPartial } from '@reduxjs/toolkit';

export enum EDisplay {
  Both = 'Both',
  LoggedIn = 'Logged in',
  LoggedOut = 'Logged Out',
}

export enum EStatus {
  Active = 'Active',
  Draft = 'Draft',
  Scheduled = 'Scheduled',
}

export type TDeviceMedia = {
  name?: string;
  base64?: string;
  link?: string;
  publicUrl?: string;
  isTwoLayer?: boolean;
  animatedLayerBase64?: string;
  layerLink?: string;
  layerName?: string;
};
export type TMedia = {
  desktop?: TDeviceMedia;
  mobile?: TDeviceMedia;
  app?: TDeviceMedia;
};

export type TData = {
  id: string;
  title: string;
  location: string[];
  states?: string[];
  media: TMedia;
  display: EDisplay;
  status: EStatus;
  punter_category: string[];
  start_date?: string;
  end_date?: string;
  platform?: TPlatformType;

  has_money_back_promo?: boolean;
  has_treasure_hunt_promo?: boolean;
  money_back_promo_id?: string;
  treasure_hunt_promo_id?: string;
};

export type TAddBannerParams = DeepPartial<{
  contentType: string;
  contentBase64: string;
  version: number | null;
}>;

export type TUploadMediaParams = DeepPartial<
  {
    name: string;
    contentBase64: string;
    contentType: string;
  }[]
>;

export type TUploadMediaData = DeepPartial<
  {
    name: string;
    image: string;
    device: string;
    publicUrl?: string;
    layerName?: string;
    layerLink: string;
  }[]
>;
