import { useQueryRaceResult } from '@/api/tradeManager/raceDetails/raceResult/raceResult.hooks';
import { RacingType } from '@/lib/Constants';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { AnyObject } from 'yup/lib/types';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../common/hooks/useRedux';
import {
  EGeneralStatus,
  TRaceExoticResult,
  TRunner,
  TRunnerResult,
} from '../../../../../../../../lib/DBModels';
import { getRaceResults } from '../../../../Services/RaceDetails.actions';
import {
  setExoticResults,
  setRaceResults,
} from '../../../../Services/RaceDetails.slices';
import { ESettleStatus } from '../Types';

/**
 * Hook to get the initial data
 */
export const useSettle = () => {
  const dispatch = useAppDispatch();
  const { raceData, ignoreWinterSwitchOn } = useAppSelector(
    (state) => state.raceDetails
  );

  const { data } = useQueryRaceResult({
    params: { race_id: raceData.race_id },
    options: {
      refetchInterval: raceData.status === EGeneralStatus.Settled ? 60000 : 0, // polling only when race status is settled
      staleTime: raceData.status === EGeneralStatus.Settled ? 0 : 60 * 5000, // 5 minutes
      /* For older components */
      onSuccess(data) {
        if (data.exotic_results) {
          dispatch(
            setExoticResults(
              (data?.exotic_results as TRaceExoticResult[]) ?? []
            )
          );
        }
        if (data.runner_results) {
          dispatch(
            setRaceResults((data?.runner_results as TRunnerResult[]) ?? [])
          );
        }
      },
    },
  });

  /* The race is finalised when all 4 results have come back */
  const isFinalised = data?.runner_results && data?.runner_results.length >= 4;

  return {
    ignoreWinterOn: ignoreWinterSwitchOn,
    initialProtestOverride: raceData.fptp_protest_override,
    showToggleProtestOverrride:
      !isFinalised && raceData.race_type !== RacingType.GREYHOUNDS,
    data: (raceData.status !== EGeneralStatus.Settled
      ? [...(raceData.runners ?? [])].sort(
          (a, b) => (a?.number ?? 0) - (b?.number ?? 0)
        )
      : [...(raceData.runners ?? [])].sort(
          (a, b) =>
            Number(a?.results_place ?? 20) - Number(b?.results_place ?? 20)
        )) as TRunner[],
    raceId: raceData.race_id,
    refetch: () => dispatch(getRaceResults(raceData.race_id ?? '')),
  };
};

/**
 * Hook to setup formik init & validation
 */
export const useFormData = () => {
  const { data, initialProtestOverride } = useSettle();

  /**
   * Loop through response data and build an object
   * from runner id's.
   */
  const initialValues = data?.reduce<Record<string, string>>(
    (acc, cur) => ({
      ...acc,
      [cur?.number ?? '']: '',
    }),
    {}
  );

  const keys = Object.keys(initialValues ?? {});

  /**
   * Loop through initialValues keys to build
   * validation for all of the runners
   */
  const validation = keys.reduce<
    Record<
      string,
      | string
      | Yup.StringSchema<string | undefined, AnyObject, string | undefined>
    >
  >(
    (acc, cur) => ({
      ...acc,
      [cur]: Yup.string()
        // .required('All fields are required') <-- create custom test
        .matches(/^[0-4]+$/, 'Please only enter 1-4')
        .test('fourRequired', '2 positions are required', (value, ctx) => {
          const formValues = ctx.parent as unknown as Record<
            string,
            number | undefined | ESettleStatus
          >;

          // ** Get all form values from parent
          const positionsArray = keys.map((k) => ctx.parent[k]);

          // ** Check for no positions
          const noPositions = positionsArray.every((v) => !v);

          // ** If the form has no values don't validate
          if (noPositions) {
            return true;
          }

          if (formValues.settle_status === ESettleStatus.Winter) return true;
          // Remove undefined values
          const valuesParsed = JSON.parse(JSON.stringify(formValues)) as Record<
            string,
            number | undefined
          >;
          const valuesNumberFiltered = Object.values(valuesParsed).filter(
            (v) => !Number.isNaN(Number(v))
          );

          if (value || valuesNumberFiltered.length >= 2) {
            return true;
          }
          return false;
        })
        .test(
          'hasPositions',
          'Your deadheat calculation is not correct',
          (value, ctx) => {
            if (ctx.parent.settle_status === ESettleStatus.Winter) return true;

            // ** Get all form values from parent and remove undefined values
            const formValues = keys.map((k) => ctx.parent[k]);
            const formValuesParse = formValues.filter((v) => v !== undefined);
            // ---

            // ** List duplicates which indicate we're in deadheat
            const detectDuplicates = formValuesParse.filter(
              (item, index) => formValuesParse.indexOf(item) !== index
            ) as number[];
            // ---

            // ** If we're in DH run the following validation
            if (detectDuplicates.length) {
              // ** Calculate how many DH there is. E.G;
              // 1,1,3,4 || 1,2,2,4 || etc
              const duplicateCount = detectDuplicates.reduce<
                Record<string, number>
              >((ac, cu) => {
                if (ac[cu]) return { ...ac, [cu]: Number(ac[cu] ?? 0) + 1 };
                // Plusing 1 here due to the init
                return { ...ac, [cu]: 1 };
              }, {});

              // There is a DH for 1st
              // 1,1,x,x
              if (duplicateCount?.[1] === 1) {
                if (Number(value) === 2) return false;
              }

              // There is a DH of 3 for 1st
              // 1,1,1,x
              if (duplicateCount?.[1] === 2) {
                if (Number(value) === 3 || Number(value) === 2) return false;
              }

              // There is a DH of 4 for 1st
              // 1,1,1,1
              if (duplicateCount?.[1] === 3) {
                if (
                  Number(value) === 4 ||
                  Number(value) === 3 ||
                  Number(value) === 2
                )
                  return false;
              }

              // There is a DH for 2nd
              // x,2,2,x
              if (duplicateCount?.[2] === 1) {
                if (Number(value) === 3) return false;
              }

              // There is a DH of 3 for 2nd
              // x,2,2,2
              if (duplicateCount?.[2] === 2) {
                if (Number(value) === 3 || Number(value) === 4) return false;
              }

              // There is a DH for 3rd
              // x,x,3,3
              if (duplicateCount?.[3] === 1) {
                if (Number(value) === 4) return false;
              }

              // There is a DH for 3rd
              // x,x,3,3,3
              if (duplicateCount?.[3] === 2) {
                if (Number(value) === 4) return false;
              }
            }
            // ---

            // ** Strip non numbers from the formValues
            const formValueNumbers = formValues.filter(
              (v) => !Number.isNaN(Number(v))
            ).length;

            // ** If the form has no values don't validate
            if (formValueNumbers === 0) {
              return true;
            }

            // ** Check all values if value is under 2.
            // This will trigger validation if submitting without 2 numbers.
            if (formValueNumbers < 2) {
              return !!value;
            }

            // ** Fin
            return true;
          }
        ),
    }),
    {}
  );

  return {
    initialValues: {
      ...initialValues,
      protest_override: initialProtestOverride,
    },
    validation: Yup.object().shape(validation as ObjectShape),
  };
};

/**
 * Hook that returns an array of validation errors
 * which get displayed within the banner.
 */
export const useValidationErrors = () => {
  const { errors, touched } = useFormikContext<Record<string, string>>();
  const errorKeys = Object.keys(errors);

  const touchedErrors = errorKeys.reduce<string[]>((acc, cur) => {
    if (touched[cur] && errors[cur]) {
      return [...acc, errors[cur] ?? ''];
    }

    return acc;
  }, []);

  return [...Array.from(new Set(touchedErrors))];
};
