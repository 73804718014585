import {
  chakra,
  ChakraComponent,
  Grid,
  GridItem,
  GridItemProps,
  GridProps,
  Icon,
  IconProps,
  Text,
  TextProps,
} from '@chakra-ui/react';

export const BCGridWrapper: ChakraComponent<'div', GridProps> = chakra(Grid, {
  baseStyle: () => ({
    gridTemplateAreas: `
        "title nav"
        "main nav"`,
    gridTemplateRows: '130px 1fr',
    gridTemplateColumns: '1fr 260px',
    gap: '4',
    color: 'blackAlpha.700',
  }),
});

export const BCGridItem: ChakraComponent<'div', GridItemProps> = chakra(
  GridItem,
  {
    baseStyle: () => ({
      pos: 'relative',
      bg: 'white',
      boxShadow: 'md',
      borderRadius: 'base',
      p: '4',
      mb: '4',
    }),
  }
);

export const IconBack: ChakraComponent<'div', IconProps> = chakra(Icon, {
  baseStyle: () => ({
    mr: '5',
    boxSize: '9',
    borderRadius: 'base',
    border: '1px',
    borderColor: 'gray.300',
    p: '1',
    cursor: 'pointer',
  }),
});

export const BCText: ChakraComponent<'div', TextProps> = chakra(Text, {
  baseStyle: () => ({
    color: 'gray.600',
  }),
});
