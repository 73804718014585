import { Button, HStack } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';

export type ETabs = 'settlement' | 'status' | 'offerings' | 'quaddies';

type TTabs = {
  isDisabled: boolean;
  selectedTab: ETabs;
  setSelectedTab: (tab: ETabs) => void;
};

export default function Tabs({
  selectedTab,
  setSelectedTab,
  isDisabled,
}: TTabs) {
  const intl = useIntl();

  return (
    <HStack px="8" pb="8">
      {[
        {
          text: intl.formatMessage({
            id: 'trademanagerpage.matchDetails.configure.header.settle',
          }),
          onClick: () => setSelectedTab('settlement'),
        },
        {
          text: intl.formatMessage({
            id: 'trademanagerpage.matchDetails.configure.header.status',
          }),
          onClick: () => setSelectedTab('status'),
        },
        {
          text: intl.formatMessage({
            id: 'tradeManagerPage.matchDetails.configure.header.offerings',
          }),
          onClick: () => setSelectedTab('offerings'),
        },
        {
          text: intl.formatMessage({
            id: 'tradeManagerPage.matchDetails.configure.header.quaddies',
          }),
          onClick: () => setSelectedTab('quaddies'),
        },
      ].map(({ text, onClick }) => (
        <Button
          key={text}
          onClick={onClick}
          disabled={isDisabled}
          size="sm"
          variant={
            selectedTab === text.toLowerCase() ? 'rounded' : 'outline-rounded'
          }
        >
          {text}
        </Button>
      ))}
    </HStack>
  );
}
