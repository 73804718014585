import React from 'react';

import Footer from './components/Footer';
import Form from './components/Form';
import { Box } from '@chakra-ui/react';
import { Form as FormikForm, FormikProvider } from 'formik';
import { useStatus } from './services/Status.hooks';

type TStatus = {
  isQuaddies?: boolean;
};

export default function Status({ isQuaddies = false }: TStatus) {
  const {
    formik,
    setRaceStatus,
    setRaceFollowFeed,
    setRaceSettleByFeed,
    setMarketStatus,
    setMarketFollowFeed,
    setMarketSettleByFeed,
  } = useStatus(isQuaddies);

  return (
    <FormikProvider value={formik}>
      <Box
        as={FormikForm}
        sx={{ display: 'flex', flexDir: 'column', flex: 1, overflow: 'auto' }}
      >
        <Form
          values={formik.values}
          setRaceStatus={setRaceStatus}
          setRaceFollowFeed={setRaceFollowFeed}
          setRaceSettleByFeed={setRaceSettleByFeed}
          setMarketStatus={setMarketStatus}
          setMarketFollowFeed={setMarketFollowFeed}
          setMarketSettleByFeed={setMarketSettleByFeed}
          isQuaddies={isQuaddies}
        />
        <Footer />
      </Box>
    </FormikProvider>
  );
}
