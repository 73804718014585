import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { TEventMarketInfo } from '../EventMarketInfo';
import { MultiWrapper } from '../EventMarketInfo.styles';
import { ERequestBetType } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.types';
import { Link } from 'react-router-dom';
import { getPositionValue } from '@/common/utils';
import dayjs from 'dayjs';

export const Multis = ({ bet }: TEventMarketInfo) => (
  <>
    {bet?.bet_legs?.map((leg, idx) => {
      const isRace = leg?.event_type?.toLowerCase() !== 'match';
      const legCount = leg?.legs?.length;
      const isMoM = !!leg?.legs;
      const event = leg?.event_data;

      const eventStart = dayjs(leg?.event_start).format('DD MMM YYYY HH:mm A');

      const raceTitle = isMoM
        ? `${legCount} Leg ${leg?.bet_description}`
        : `${event?.market_name} - ${event?.venue_name} R${event?.race_number}`;
      const sportTitle = isMoM
        ? `${legCount} Leg ${leg?.bet_description}`
        : `${event?.proposition_name} - ${event?.market_name}`;

      const legDescription = isMoM
        ? `${event?.venue_name} R${event?.race_number} - ${eventStart}`
        : `${
            event?.runner_number
          }. ${event?.runner_name?.toLocaleLowerCase()} - ${eventStart}`;

      const isSGMBet = bet.type === ERequestBetType.SGMulti;
      const isSRMBet = bet.type === ERequestBetType.SRMulti;
      const srmEventLink = {
        pathname: `/trade-manager/${leg?.event_type?.toLowerCase()}-details`,
        search: `?id=${leg?.event_id}&marketType=srmulti`,
        state: { from: location },
      };
      const readableProposition = (prop: string) => getPositionValue(prop);

      return (
        <>
          <MultiWrapper key={`leg-${leg?.bet_leg_id}`}>
            {isSRMBet ? (
              <Link to={srmEventLink}>
                {idx === 0 && (
                  <Text fontWeight="semibold" textTransform="capitalize">
                    {raceTitle.split('-')[1]}
                  </Text>
                )}
                <Box paddingTop={2}>
                  <Text textTransform="capitalize">
                    {event?.runner_number}.{' '}
                    {event?.runner_name?.toLocaleLowerCase()}
                    {` - `}
                    {leg?.bet_description}
                  </Text>
                </Box>
                <Text float="right">{leg?.odds?.toFixed(2)}</Text>
              </Link>
            ) : (
              <>
                <Box>
                  <Text fontWeight="semibold">
                    {isRace ? raceTitle : sportTitle}
                  </Text>
                  <Text textTransform="capitalize">
                    {!isSGMBet && // Only render if isSGMBet is false
                      (isRace
                        ? legDescription
                        : `${event?.match_name} - ${eventStart}`)}
                  </Text>
                </Box>

                <Text float="right" fontWeight="semibold">
                  {leg?.odds?.toFixed(2)}
                </Text>
              </>
            )}
          </MultiWrapper>
          {leg?.legs?.map((leg) => (
            <MultiWrapper pl="4" key={`leg-${leg?.bet_leg_id}`}>
              <Box>
                <Text fontWeight="semibold" textTransform="capitalize">
                  {!isSGMBet && // Only render if isSGMBet is false
                    (isRace
                      ? `${
                          event?.runner_number
                        }. ${event?.runner_name?.toLocaleLowerCase()}`
                      : event?.proposition_name)}
                </Text>
                <Text>
                  {isRace
                    ? readableProposition(event?.proposition_type ?? '')
                    : `${event?.market_name}`}
                </Text>
              </Box>
            </MultiWrapper>
          ))}
        </>
      );
    })}
  </>
);
